.Scheudle {

}

.ScheduleAccordionHeader {
    background-color: red;
}

.ScheduleAccordian {
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    margin-bottom: 16px;
    padding: 24px;
}

.ScheduleAccordionTitle {
    font-size: 14px;
    font-weight: 600;
}
.ScheduleAccordianDate {
    font-size: 12px;
}
.ScheduleAccordianDuration {
    font-size: 12px;
}
.ScheduleAccordianHeader {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.panel {
    border-radius: 50px !important;
}
.accordion-item {
    border-radius: 0px;
    background-color: yellow !important;
}

.ScheduleAccordianData {
    margin-top: 24px;
}

.Speakers {
    padding-bottom: 32px;
}
.Speaker {
    display: grid;
    grid-template-columns: 20% auto;
    column-gap: 32px;
    grid-template-areas: "speaker-info speaker-presentation";
    padding: 24px;
    background-color: #eeeeee;
    margin-top: 16px;
}
.SpeakerInfo {
    grid-area: speaker-info;
    display: flex;
    flex-direction: column;
}
.SpeakerPresentation {
    grid-area: speaker-presentation;
}
.SpeakerName {
    font-size: 16px;
    font-weight: 600;
}
.SpeakerInsitution {
    font-size: 14px;
    margin-bottom: 16px;
}
.SpeakerProfile {
    font-size: 12px;
}
.SpeakerProfile a {
    color: rgb(10, 23, 87);
    text-decoration: none;
    padding-bottom: 4px;
    border-bottom: 1px solid rgb(10, 23, 87);
}
.SpeakerPresentationTitle {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
}
.SpeakerAbstractLineMultiPart:after {
    content: "\00a0"
}
.SpeakerPresentationAbstract {
    font-size: 14px;
    line-height: 24px;
    margin-top: 16px;
}
.SpeakerPresentationAbstract:first-child {
    margin-top: 8px;
}
.SpeakerPresentationAbstract .mathrm {
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    .Speaker {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        grid-template-areas: "speaker-info" "speaker-presentation";
    }
    .SpeakerInsitution {
        margin-bottom: 8px;
    }
    .SpeakerProfile {
        margin-bottom: 16px;
    }
    .SpeakerInfo {
        margin-bottom: 16px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
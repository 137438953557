.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
    background-color: rgb(81, 155, 224);
    color: white;
}

.accordion-active {
    /* color:red; */
}

/*#region home */


.home__title-small {
    color: #1a1a1a;
    font-size: 12pt;
    /* border-bottom: 1px solid #1a1a1a; */
    padding-top: 50px;
    margin-bottom: 0px;
    /* padding-bottom: 7px; */
}

.home__title {
    color: #1a1a1a;
    font-size: 28pt;
    font-weight: 300;
    /* border-bottom: 1px solid #1a1a1a; */
    /* padding-top: 50px; */
    margin-top: 0px;
    padding-bottom: 7px;
}
.home__subtitle {
    color: #1a1a1a;
    font-size: 1.6rem;
    font-weight: 600;
    /* border-bottom: 1px solid #1a1a1a; */
    /* padding-top: 50px; */
    margin-top: 0px;
    margin-bottom: 4rem;
    padding-bottom: 0px;
}

.home__sentance {
    color: #1a1a1a;
    font-size: 12pt;
    line-height: 1.7;
    margin-bottom: 17pt;
    letter-spacing: 0.2pt;
}

.col-lg-5{
    display: flex;
}

.home__seminarcard {
    width: 100%;
    border-radius: 15px !important;
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    margin-bottom: 20px;
    padding: 20px !important;
    background: #ffffff !important;
    border: none !important;
    text-align: center;
    -webkit-box-shadow: 0px 3px 10px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 3px 10px 4px rgba(0, 0, 0, 0.2);
    background: linear-gradient(5deg, rgba(255,255,255,1) 15%, rgba(245,245,245 ,1) 30%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100%) !important;
}

.home__seminarcard2 {
    /* width: 260px; */
    width: 50%;
    border-radius: 15px !important;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    background: #ffffff !important;
    border: none !important;
    -webkit-box-shadow: 0px 3px 10px 4px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 3px 10px 4px rgba(0, 0, 0, 0.2);
    padding: 20px !important;
}

.home__seminarcard--peach {
    background-color: rgba(255, 245, 248, 1) !important;
    /* background: linear-gradient(0deg, rgba(255,154,156,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100%) !important; */
}
.home__seminarcard--black {
    background: linear-gradient(5deg, rgba(20,20,20,1) 15%, rgba(30,30,30 ,1) 30%, rgba(40,40,40,1) 30%, rgba(60,60,60,1) 100%) !important;

}

.home__seminarcard--blue {
    background-color: rgba(245, 252, 255, 1) !important;
    /* background: linear-gradient(0deg, rgba(154,218,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100%) !important; */
}

.home__seminarcard--green {
    background-color: rgba(245, 255, 246, 1) !important;
    /* background: linear-gradient(0deg, rgba(192,255,154,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100%) !important;
    background: linear-gradient(0deg, rgba(192,255,154,1) 0%, rgba(239,255,229,1) 30%, rgba(239,255,229,1) 100%) !important; */
}

.home__seminarcard--yellow {
    background-color: rgba(254, 255, 245, 1) !important;
    /* background: linear-gradient(0deg, rgba(255,251,154,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,1) 100%) !important; */
}

.home__seminarcard__img {
    /* border-radius: 15px !important; */
    height: 100% !important;
}

.home__seminarcard__img2 {
    border-radius: 15px !important;
    /* width: 80% !important; */
    height: 80px !important;
}

.home__seminarcard__title {
    margin-bottom: 30px !important;
    margin-top: 20px !important;
    color: #1a1a1a;
    font-size: 14pt !important;
    font-weight: 600 !important;
}

.home__seminarcard__title2 {
    text-align: left;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    color: #092f56;
}

.home__seminarcard__title--peach {
    color: #802628;
}

.home__seminarcard__title--blue {
    color: #265f80;
}

.home__seminarcard__title--yellow {
    color: #807d26;
    color: #222222;
}

.home__seminarcard__title--green {
    color: #488026;
}

.home__seminarcard__subtitle {
    margin-bottom: 0px !important;
    font-size: 10pt !important;
    font-weight: normal;
    padding-bottom: 16px;
}

.home__seminarcard__subtitle2 {
    margin-bottom: 15px !important;
    text-align: left;
    font-size: 10pt !important;
    font-weight: normal;
}

.home__seminarcard__text {
    font-size: 10pt !important;
}

.home__seminarcard__text2 {
    font-size: 10pt !important;
    text-align: left;
    padding-left: 0px !important;
}

.btn-blue {
    border: 1px solid #092f56 !important;
    color: #092f56;
    margin: 0 !important;
}

.btn-dark {
    border: 1px solid #1a1a1a;
    background-color: transparent !important;
    color: #1a1a1a !important;
    margin-bottom: 10px;
}

.btn-dark:hover {
    background-color: #1a1a1a !important;
    color: #ffffff !important;
}

.btn-blue:hover {
    background-color: #092f56;
    color: #ffffff !important;
}

.btn-peach {
    border: 1px solid #802628 !important;
    color: #802628 !important;
}

.btn-peach:hover {
    background-color: #802628;
    color: #ffffff !important;
}

.btn-green {
    border: 1px solid #488026 !important;
    color: #488026 !important;
}

.btn-green:hover {
    background-color: #488026;
    color: #ffffff !important;
}

/* .btn-yellow {
    border: 1px solid #807d26 !important;
    color: #807d26 !important;
}
.btn-yellow:hover {
    background-color: #807d26;
    color: #ffffff !important;
} */
/* style={{ width: 260, marginLeft: 10, marginRight: 10, marginBottom: 20, borderRadius: 15}} */
/*#endregion */


/*#region header */
.header__title {
    padding-left: 10px;
    color: #ffffff;
    display: flex;
    font-weight: 600;
    font-size: 10pt;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 57px;
}
.header__register__button {
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 57px;
    /* text-align: center; */
}
/*#endregion */

/*#region committee */
.committee__member {
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.committee__member__name {
    padding-bottom: 0px;
    margin-bottom: 0px;
}
.committee__member__url {
    font-size: 10pt;
    padding-left: 10px;
}
/*#endregion */


/*#region footer */
.footer {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    text-align: center; 
}
.footer__institution {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center !important;
    margin-bottom: 10px;
}
.footer__institution__img {
    width: 80px; 
    padding-right: 10px;
}
.footer__institution__group {
    color: #cccccc; 
    font-size: 9pt;
    margin-bottom: 0;
    margin-top: 15px;
    margin-bottom: 15px;
}
.footer__acknowledgements {
    color: #cccccc; 
    font-size: 9pt;
    margin-bottom: 0;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
}
.footer__contactus {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
}
/*#endregion */
@media screen and (min-width: 576px) {
    .committee__member {
        flex-direction: row;
        align-items: center;
    }
}

@media screen and (min-width: 768px) {
    .header__register__button {
        align-items: flex-end;
    }
}

@media screen and (min-width: 992px) {
    
    .footer {
      flex-direction: row;
    }
    .footer__institution {
        justify-content: flex-start !important;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .footer__contactus {
        text-align: right;
        margin-top: 10px;
        margin-bottom: 0px;
    }
    .footer__acknowledgements {
        margin-top: 10px;
        margin-bottom: 0px;
    }
    .header__title {
        font-size: 12pt;
    }
  }
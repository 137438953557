.Home {

}
.HomeModal {
    border-radius: 0px !important;
    border: 0px solid #000000 !important;
}
.HomeModalClose {
    cursor: pointer;
    color: #666666;
    font-size: 10px;
    padding: 0px 6px 6px 6px;
}
.HomeModalDate {
    font-size: 14px;
    margin-bottom: 12px;
}
.HomeModalDuration {
    font-size: 12px;
}
.HomeModalHeader {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    justify-content: space-between;
}
.HomeModalTitle {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
}
.HomeModalSession {
    margin-bottom: 16px;
}
.HomeModalSession:last-child {
    margin-bottom: 0px;
}
.HomeModalSessionTitle {
    font-size: 14px;
    font-weight: 800;
    /* margin-bottom: 4px; */
}
.HomeModalSessionTime {
    font-size: 12px;
}
.HomeModalSessionChair {
    font-size: 12px;
    margin-bottom: 12px;
}
.HomeModalSessionEvent {
    font-size: 14px;
    font-weight: 400;
}
.HomeModalSessionKeyNote {
    font-size: 14px;
    font-weight: 700;
}
.HomeModalSessionPresentation {
    font-size: 12px;
}
.HomeModalSessionLink {
    cursor: pointer;
    color: #2936ed;
    font-size: 12px;
}